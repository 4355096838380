import { create } from 'zustand';

export type ModalType = 'info' | 'error' | 'confirm' | 'delete';

export type ModalInfo = {
    id?: string;
    type?: ModalType;
    message?: string;
    onSuccess?: () => void;
    onClose?: () => void;
};

export type ModalState = {
    modalStack: ModalInfo[];
};

export type ModalActions = {
    modalShow: (params: ModalInfo) => void;
    modalHide: (id: string) => void;
};

export type ModalStore = ModalState & ModalActions;

export const initModalStore = (): ModalState => {
    return {
        modalStack: [],
    };
};

export const defaultInitState: ModalState = {
    modalStack: [],
};

export const useModalStore = create<ModalStore>()((set, get) => ({
    ...defaultInitState,
    modalHide: (id) => {
        const filterMap = get().modalStack.filter((modal) => modal.id !== id);
        set((state) => ({ modalStack: [...filterMap] }));
    },
    modalShow: (params) => {
        set((state) => ({ modalStack: [...state.modalStack, params] }));
    },
}));
